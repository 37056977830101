import * as apihelper from "../selectors/apihelper";
import { ResourceObject, ResourceObjects } from "./models";
import StateMerge from "./stateMerge/StateMerge";

const limitToSet = (
  ids: string[],
  entities: ResourceObjects,
  entityName: string
) => {
  return entities;
  /*
  return ids.map((id) => {
    let entity = entities.find((e) => apihelper.entityHasId(e, id));
    if (!entity) {
      console.error(
        "HourSet could not find " +
          entityName +
          " with id " +
          id +
          " referenced by time registration"
      );
      //throw "HourSet could not find " + entityName + " with id " + id + " referenced by time registration";
    }
    return entity;
  });
*/
};

const genIdx = (
  entities: ResourceObjects,
  keyFn: (e: ResourceObject) => string = (e) =>
    apihelper.getEntityId(e) as string
) =>
  (entities || []).reduce(
    (acc: Record<string, ResourceObject>, cur: ResourceObject) => {
      acc[keyFn(cur) as string] = cur;
      return acc;
    },
    {}
  );
const groupBy = (
  entities: ResourceObjects,
  keyFn: (obj: ResourceObject) => string
) =>
  (entities || []).reduce((acc, cur: ResourceObject) => {
    const key = keyFn(cur);
    acc[key] = acc[key] || [];
    acc[key].push(cur);
    return acc;
  }, {} as Record<string, ResourceObjects>);

export default class HourSet {
  public stateMerge: StateMerge;
  private projects: ResourceObjects;
  private resources: ResourceObjects;
  private contractRoles: ResourceObjects;
  private projectResources: ResourceObjects;
  private defaultProjectResources: Record<string, ResourceObjects> = {};
  private contracts: ResourceObjects;
  private clients: ResourceObjects;
  private projectIdx: Record<string, ResourceObject>;
  private projectResourceIdx: Record<string, ResourceObjects>;
  private clientIdx: Record<string, ResourceObject>;
  private contractIdx: Record<string, ResourceObject>;
  private contractRoleIdx: Record<string, ResourceObject>;
  private contractTocontractRoleIdx: Record<string, ResourceObjects>;

  constructor(
    stateMerge: StateMerge,
    projects: ResourceObjects,
    resources: ResourceObjects,
    projectResources: ResourceObjects,
    clients: ResourceObjects,
    contracts: ResourceObjects,
    contractRoles: ResourceObjects
  ) {
    this.stateMerge = stateMerge;
    this.projects = limitToSet(
      stateMerge.getReferencedProjectIds(),
      projects,
      "project"
    );
    this.projectIdx = genIdx(this.projects);
    this.resources = limitToSet(
      stateMerge.getReferencedResourceIds(),
      resources,
      "resource"
    );
    this.contractRoles = limitToSet(
      stateMerge.getReferencedContractRoleIds(),
      contractRoles,
      "contract role"
    );

    this.projectResources = projectResources;
    this.projectResourceIdx = groupBy(
      this.projectResources,
      (pr: ResourceObject) => apihelper.getRelId(pr, "resource") as string
    );
    if (Array.isArray(this.projectResources)) {
      this.defaultProjectResources = groupBy(
        this.projectResources.filter((pr) =>
          apihelper.getAttr(pr, "isDefaultProjectResource")
        ),
        (pr: ResourceObject) => apihelper.getRelId(pr, "project") as string
      );
    }
    this.clients = clients;
    this.clientIdx = genIdx(this.clients);
    this.contracts = contracts;
    this.contractIdx = genIdx(this.contracts);
    this.contractRoles = contractRoles;
    this.contractTocontractRoleIdx = groupBy(
      this.contractRoles,
      (cr: ResourceObject) => apihelper.getRelId(cr, "contract") as string
    );
    this.contractRoleIdx = genIdx(this.contractRoles);
  }

  equals(otherHourSet: HourSet) {
    return (
      this.stateMerge === otherHourSet.stateMerge &&
      this.projects === otherHourSet.projects &&
      this.resources === otherHourSet.resources &&
      this.contractRoles === otherHourSet.contractRoles &&
      this.projectResources === otherHourSet.projectResources &&
      this.defaultProjectResources === otherHourSet.defaultProjectResources &&
      this.clients === otherHourSet.clients &&
      this.contracts === otherHourSet.contracts &&
      this.contractRoles === otherHourSet.contractRoles
    );
  }

  getClientForProject(project: ResourceObject) {
    const co = this.getContractForProject(project);
    const clId = co && (apihelper.getRelId(co, "client") as string);
    return this.clientIdx[clId];
  }

  getContractForProject(project: ResourceObject) {
    const coId = apihelper.getRelId(project, "contract") as string;
    return this.contractIdx[coId];
  }

  getProjectResourcesForResourceAndProject(
    resource: ResourceObject,
    project: ResourceObject | ResourceObjects
  ) {
    const projects = Array.isArray(project) ? project : [project];
    const resourceId = apihelper.getEntityId(resource) as string;
    let baseList: ResourceObjects = projects.map(project => this.defaultProjectResources[apihelper.getEntityId(project) as string] || []).flatten();
    baseList = baseList.concat(this.projectResourceIdx[resourceId] || []);
    return baseList.filter((pr: ResourceObject) =>
        projects.some(project => apihelper.relRefersToEntity(pr, "project", project))
    );
  }

  getContractRolesForContract(contract: ResourceObject) {
    return this.contractTocontractRoleIdx[
      apihelper.getEntityId(contract) as string
    ];
  }

  getContractRoleForTimeRegistration(timeRegistration: ResourceObject) {
    return this.contractRoleIdx[
      apihelper.getRelId(timeRegistration, "contractRole") as string
    ];
  }

  getResourceForTimeRegistration(timeRegistration: ResourceObject) {
    return this.resources.find((r) =>
      apihelper.relRefersToEntity(timeRegistration, "resource", r)
    );
  }

  getContractRolesForProject(project: ResourceObject) {
    const co = this.getContractForProject(project);
    return this.getContractRolesForContract(co);
  }

  getContractForContractRole(contractRole: ResourceObject) {
    const coId = apihelper.getRelId(contractRole, "contract") as string;
    return this.contractIdx[coId];
  }

  getProjectById(projectId: string) {
    return this.projectIdx[projectId];
  }

  getProjectForTimeRegistration(treg: ResourceObject) {
    return this.projectIdx[apihelper.getRelId(treg, "project") as string];
  }

  getTimeRegFailedValidations(treg: ResourceObject) {
    const validations = this.stateMerge.getFailedValidations(treg);
    const project = this.getProjectForTimeRegistration(treg);
    if (project) {
      if (
        apihelper.getAttr(project, "needsDescription") &&
        !apihelper.getAttr(treg, "description")
      ) {
        validations.description = validations.description || [];
        validations.description.push(
          "Time registrations for this project require a non-blank description"
        );
      }
    }
    return validations;
  }

  getClients = () => this.clients;
  getProjects = () => this.projects;
  getContracts = () => this.contracts;
}
