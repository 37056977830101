import React, { Component } from "react";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { Callout } from "@fluentui/react/lib/Callout";
import {
  SearchBox,
  Stack,
  Checkbox,
  ScrollablePane,
  mergeStyleSets,
} from "@fluentui/react";
import PropTypes from "prop-types";
import "../../stylesheets/app.scss";
import { Icon } from "@fluentui/react/lib/Icon";

/*
Props input:
    allOptions: [{ key: 'A', text: 'Option A' },
                 { key: 'B', text: 'Option B' },
                 { key: 'C', text: 'Option C' },]

    selected:   ['A', 'B',]

    onSelected: (keys, keyId, wasAdded) =>

Return function:
    onSelected(keys, keyId, wasAdded)

example:
    keys: ['A', 'B']
    keyId, 'A'
    wasAdded: true

keyId can be a list, which removes all keyId from keys. This require wasAdded is false.
*/

class GroupOfCheckboxes extends Component {
  constructor(props) {
    super(props);

    this._renderControlledCheckbox = this._renderControlledCheckbox.bind(this);

    this.state = {
      keysToDisplay: [],
      checkedKeys: [],
      stackTokens: { childrenGap: 10 },
    };
  }

  static defaultProps = {
    allOptions: [],
    selected: [],
    onSelected: () => null,
    filterFunction: null,
    headerText: "",
    doShowIfEmpty: false,
    howManyOptions: Infinity,
  };

  componentDidMount() {
    this.setState({
      keysToDisplay: this.props.allOptions,
      checkedKeys: this.props.selected,
    });
  }

  _handleChange(key, wasChecked) {
    const myKeys = this.props.selected;
    const updatedKeys = wasChecked
      ? [...myKeys, key]
      : myKeys.filter((k) => k !== key);
    this.setState({ checkedKeys: updatedKeys });

    const returnValue = { keys: myKeys, keyId: key, wasAdded: wasChecked };

    this.props.onSelected(returnValue);
  }

  _renderControlledCheckbox(opt) {
    return (
      <Checkbox
        className="novatime-custompicker-checkbox"
        key={opt.key}
        label={<div className="novatime-custompicker-text">{opt.text}</div>}
        checked={this.props.selected.some((k) => k === opt.key)}
        onChange={(_, wasChecked) => this._handleChange(opt.key, wasChecked)}
      />
    );
  }

  render() {
    const { headerText, doShowIfEmpty, filterFunction, howManyOptions } =
      this.props;
    const { keysToDisplay } = this.state;

    const optionsToShow = filterFunction
      ? keysToDisplay
          .filter((opt) => filterFunction(opt))
          .map((opt) => this._renderControlledCheckbox(opt))
      : keysToDisplay.map((opt) => this._renderControlledCheckbox(opt));

    const isEmptyOptionsToShow = optionsToShow?.length === 0;

    return (
      <Stack
        className="novatime-custompicker-groupofcheckboxes-container"
        tokens={this.state.stackTokens}
      >
        {doShowIfEmpty && isEmptyOptionsToShow && (
          <div align="center">List is empty</div>
        )}

        {!isEmptyOptionsToShow && headerText && (
          <div
            align="center"
            className="novatime-custompicker-groupofcheckboxes-header"
          >
            {headerText}
          </div>
        )}

        {!isEmptyOptionsToShow && (
          <Stack
            className="novatime-custompicker-groupofcheckboxes"
            tokens={this.state.stackTokens}
          >
            {optionsToShow.slice(0, howManyOptions)}
          </Stack>
        )}
      </Stack>
    );
  }
}

export default class CustomPicker extends Component {
  constructor(props) {
    super(props);
    this._toggleDoShowCallout = this._toggleDoShowCallout.bind(this);
    this._hideCheckboxesPrimary = this._hideCheckboxesPrimary.bind(this);
    this._showCheckboxesPrimary = this._showCheckboxesPrimary.bind(this);
    this._onChangeSearchbox = this._onChangeSearchbox.bind(this);
    this._onClickAddmoreOptions = this._onClickAddmoreOptions.bind(this);
    this._onClickClearSelected = this._onClickClearSelected.bind(this);
    this._onClickSelectAll = this._onClickSelectAll.bind(this);
    this._onClickShowAll = this._onClickShowAll.bind(this);

    this.state = {
      doShowCallout: false,
      doShowCheckboxesPrimary: true,
      doShowPlusShowMoreButton: false,
      searchText: "",
      howManyOptionsTop: 0,
      doShowDivider: false,
      howManyOptionsDownDefault: 15,
      howManyOptionsDown: 0,
      plusMoreIncrement: 5,
      stackTokens: { childrenGap: 10 },
      doShowCheckboxesPrimaryFirst: false,
      doShowCheckboxesPrimarySecond: false,
    };
    this.buttonRef = React.createRef();
    this.searchRef = React.createRef();
  }

  static defaultProps = {
    allOptions: [],
    selected: [],
    onSelected: null,
    onClear: null,
    onSelectAll: null,
    label: "",
    placeholder: "",
  };

  static propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    allOptions: PropTypes.array,
    selected: PropTypes.array,
    onSelected: PropTypes.func,
    onClear: PropTypes.func,
    onSelectAll: PropTypes.func,
  };

  componentDidMount() {
    this.setState({
      howManyOptionsTop: this.props.selected.length,
    });
  }

  _toggleDoShowCallout() {
    this.setState({ doShowCallout: !this.state.doShowCallout });
    if (!this.state.doShowCallout) {
      this._showCheckboxesPrimary();
    }
  }

  _hideCheckboxesPrimary() {
    this.setState({ doShowCheckboxesPrimary: false });
  }

  _showCheckboxesPrimary() {
    this.setState({
      doShowCheckboxesPrimary: true,
      searchText: "",
      howManyOptionsTop: this.props.selected.length,
      howManyOptionsDown: this.state.howManyOptionsDownDefault,
      doShowCheckboxesPrimaryFirst: this.props.selected.length !== 0,
      doShowCheckboxesPrimarySecond:
        this.props.allOptions.length !== this.props.selected.length,
    });
  }

  _onChangeSearchbox(newValue) {
    if (newValue === "") {
      this._showCheckboxesPrimary();
    } else {
      this._hideCheckboxesPrimary();
    }
    this.setState({ searchText: newValue });
  }

  _onClickAddmoreOptions() {
    this.setState({
      howManyOptionsDown:
        this.state.howManyOptionsDown + this.state.plusMoreIncrement,
    });
  }

  _onClickShowAll() {
    this.setState({ howManyOptionsDown: Infinity });
  }

  _onClickClearSelected() {
    this.props.onClear();
  }

  _onClickSelectAll() {
    this.props.onSelectAll();
  }

  render() {
    const {
      searchText,
      howManyOptionsTop,
      howManyOptionsDown,
      doShowCallout,
      doShowCheckboxesPrimary,
      doShowCheckboxesPrimaryFirst,
      doShowCheckboxesPrimarySecond,
    } = this.state;

    const { allOptions, selected, onSelected } = this.props;

    let textOnToggleButton = "";
    selected.slice(0).map((keySelected) => {
      const keyName = allOptions.find((opt) => opt.key === keySelected)?.text ?? "";
      textOnToggleButton = textOnToggleButton
        ? textOnToggleButton + ", " + keyName
        : keyName;
    });
    const afterTextOnToggleButton = selected.length > 0 ? selected.length : "";

    const checkedKeys = allOptions.filter((opt) =>
      selected.some((k) => k === opt.key)
    );
    const notCheckedKeys = allOptions.filter((opt) =>
      selected.every((k) => k !== opt.key)
    );

    const doShowPlusShowMoreButton =
      howManyOptionsDown + howManyOptionsTop < allOptions.length;

    const classNames = mergeStyleSets({
      wrapper: {
        height: "50vh",
        position: "relative",
        marginRight: "5px",
        marginBottom: "5px",
        maxHeight: "479px",
      },
      textContent: {
        padding: "15px 10px",
      },
    });

    return (
      <div>
        {this.props.label && (
          <label className="novatime-custompicker-label-text">
            {" "}
            {this.props.label}{" "}
          </label>
        )}
        <div ref={this.buttonRef} className="novatime-custompicker">
          <DefaultButton
            className="novatime-custompicker-mainbutton"
            onClick={this._toggleDoShowCallout}
          >
            <span className="novatime-custompicker-mainbutton-text">
              {textOnToggleButton === ""
                ? this.props.placeholder
                : textOnToggleButton}
            </span>

            {afterTextOnToggleButton && (
              <span className="novatime-custompicker-mainbutton-number">
                {afterTextOnToggleButton}
              </span>
            )}

            <Icon iconName="ChevronDown" />
          </DefaultButton>

          {doShowCallout && (
            <Callout
              className="novatime-custompicker-callout"
              isBeakVisible={false}
              setInitialFocus
              target={this.buttonRef}
              onDismiss={this._toggleDoShowCallout}
              onBlur={() =>
                this.searchRef.current && this.searchRef.current.focus()
              } // For keeping forcus on searcbox
            >
              <Stack>
                <SearchBox
                  className="novatime-custompicker-searchbox"
                  componentRef={this.searchRef}
                  placeholder="Search..."
                  onClick={this._hideCheckboxesPrimary}
                  onChange={(_, newValue) => this._onChangeSearchbox(newValue)}
                  onClear={this._showCheckboxesPrimary}
                  onEscape={this._showCheckboxesPrimary}
                />

                {doShowCheckboxesPrimary && (
                  <span className="novatime-custompicker-checkboxesprimary-container">
                    <ScrollablePane className={classNames.wrapper}>
                      <Stack className="dummy name because DevTools gives error if there is no name">
                        {doShowCheckboxesPrimaryFirst && (
                          <GroupOfCheckboxes
                            allOptions={checkedKeys}
                            selected={selected}
                            onSelected={onSelected}
                          />
                        )}

                        {doShowCheckboxesPrimaryFirst &&
                          doShowCheckboxesPrimarySecond && (
                            <div
                              align="center"
                              class="novatime-custompicker-groupofcheckboxes-divider"
                            />
                          )}

                        {doShowCheckboxesPrimarySecond && (
                          <GroupOfCheckboxes
                            allOptions={notCheckedKeys}
                            selected={selected}
                            onSelected={onSelected}
                            headerText="Choose more:"
                            howManyOptions={howManyOptionsDown}
                          />
                        )}
                      </Stack>
                    </ScrollablePane>

                    {doShowPlusShowMoreButton && (
                      <DefaultButton
                        className="novatime-custompicker-button"
                        onClick={() => this._onClickShowAll()}
                      >
                        <span className="novatime-custompicker-button-lefttext" />
                        <span className="novatime-custompicker-button-centertext">
                          Show all
                        </span>
                        <span className="novatime-custompicker-button-righttext">
                          {howManyOptionsDown + howManyOptionsTop} /{" "}
                          {allOptions.length}
                        </span>
                      </DefaultButton>
                    )}
                  </span>
                )}

                {!doShowCheckboxesPrimary && (
                  <ScrollablePane className={classNames.wrapper}>
                    <GroupOfCheckboxes
                      allOptions={notCheckedKeys}
                      filterFunction={(opt) =>
                        opt.text
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                      }
                      selected={selected}
                      onSelected={onSelected}
                      headerText="Choose more:"
                      doShowIfEmpty={true}
                    />
                  </ScrollablePane>
                )}
                <Stack horizontal>
                  {this.props.onClear && (
                    <DefaultButton
                      className="novatime-custompicker-button"
                      disabled={selected.length === 0}
                      onClick={() => this._onClickClearSelected()}
                    >
                      <span className="novatime-custompicker-button-centertext">
                        Clear selected
                      </span>
                    </DefaultButton>
                  )}

                  {this.props.onSelectAll && (
                    <DefaultButton
                      className="novatime-custompicker-button"
                      disabled={selected.length === allOptions.length}
                      onClick={() => this._onClickSelectAll()}
                    >
                      <span className="novatime-custompicker-button-centertext">
                        Select all
                      </span>
                    </DefaultButton>
                  )}
                </Stack>
              </Stack>
            </Callout>
          )}
        </div>
      </div>
    );
  }
}
