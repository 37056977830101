import * as types from '../../constants/ActionTypes';
import {getAllTimeRegistrationSummaries} from '../../selectors/actions/timeRegistrationSummary';
import {apiRequestParameter} from '../../selectors/actionhelper';

interface timeRegistrationSummaryState {
  timeRegistrationSummariesByMonth: {
    [key: string]: string | undefined
  },
  storeRevision: number
}

const initialState: timeRegistrationSummaryState = {
  timeRegistrationSummariesByMonth: {},
  storeRevision: 0,
};

export default function timeRegistrationSummaryReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.API_TIMEREGISTRATIONSUMMARY_GET_ALL_SUCCESS:
    {
      const startTimeString = apiRequestParameter(action, "startTime");
      if (!startTimeString) { return state; }
      const startTimeDate = new Date(startTimeString);
      const summaryKey = `${startTimeDate.getFullYear()}${startTimeDate.getMonth()}`;
      const allSummaries = {
        ...state.timeRegistrationSummariesByMonth, [summaryKey]: getAllTimeRegistrationSummaries(action)
      };
      state = Object.assign({}, state, { timeRegistrationSummariesByMonth: allSummaries, storeRevision: state.storeRevision + 1 });
      return state;
    }

    default:
      return state;
  }
}
