import {getAttr} from "../selectors/apihelper";
import { ResourceObject } from "./models";

const getEffectiveDate = (projectResource: ResourceObject) => {
    const effectiveDate = new Date(getAttr(projectResource, 'effectiveDate') as string);
    effectiveDate.setHours(0, 0, 0, 0);
    return effectiveDate;
}

const getExpiryDate = (projectResource: ResourceObject) => {
    const expiryDate = new Date(getAttr(projectResource, 'expiryDate') as string);
    expiryDate.setHours(23, 59, 59, 999);
    return expiryDate;
}

export const isProjectResourceActiveInMonth = (pr: ResourceObject, monthStart: Date, monthEnd: Date) =>
    getEffectiveDate(pr) <= monthEnd && monthStart <= getExpiryDate(pr);

export const isProjectResourceActiveAtDate = (date: Date) => (pr: ResourceObject) =>
    getEffectiveDate(pr) <= date && date <= getExpiryDate(pr);
